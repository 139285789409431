/** @jsx jsx */
import React from 'react'
import { jsx, Box, Flex, Text } from 'theme-ui';
import Wrapper from '../layout/wrapper'
import Inner from '../layout/inner'
import { useStaticQuery, graphql, Link } from "gatsby"
import style from './partners.style'

const PartnerCard = ({title, slug, logo}) => {

  return (
    <Link to={`/partners/${slug}`} key={slug}>
      <img src={logo?.file?.url} alt={title} />
    </Link>
  )
}

const Partners = () => {

  const data = useStaticQuery(graphql`
    query FooterPartners {
      contentfulFooter {
        tierOnePartners {
          title
          logo {
            file {
              url
            }
          }
          slug
        }
        tierTwoPartners {
          title
          logo {
            file {
              url
            }
          }
          slug
        }
      }
    }
  `)
  
  const tierOne = data.contentfulFooter.tierOnePartners
  const tierTwo = data.contentfulFooter.tierTwoPartners
  

  return (
    <React.Fragment>
      <Box sx={style.zebra} />
      <Box sx={style.wrapper}>
        <Box sx={style.border}>
          <Wrapper>
            <Inner>
              <Text sx={style.title}>Partners</Text>
              <Flex sx={style.rowOne}>
                {tierOne.map((x,i) => (
                  <PartnerCard {...x} key={i} />
                ))}
              </Flex>
            </Inner>
          </Wrapper>
        </Box>
        <Box>
          <Wrapper>
            <Inner>
              <Flex sx={style.rowTwo}>
                {tierTwo.map((x,i) => (
                  <PartnerCard {...x} key={i} />
                ))}
              </Flex>
            </Inner>
          </Wrapper>
        </Box>
      </Box>
    </React.Fragment>
  )
}

export default Partners
/** @jsx jsx */
import { jsx } from 'theme-ui'
import { SubMenu } from '../menu'
import { graphql, useStaticQuery } from 'gatsby'

const CollectionsMenu = props => {
  const query = useStaticQuery(graphql`
    query CollectionsQuery {
      contentfulShop {
        collections {
          title
          slug
          isProduct
          hero {
            file {
              url
            }
          }
          thumbnail {
            file {
              url
            }
          }
        }
      }
    }
  `)

  const collections = query?.contentfulShop?.collections

  return (
    <SubMenu
      items={collections?.map(x => {
        if (!x.isProduct) {
          return {
            url: `/shop/collection/${x.slug}`,
            label: x.title,
            active:
              props.path.startsWith('/shop/collection/') &&
              props.pathContext?.slug === x.slug,
          }
        }
      })}
    />
  )
}

export default CollectionsMenu

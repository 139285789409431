/** @jsx jsx */
import { jsx, Box } from 'theme-ui';

const Circle = (props) => {

  const {width, height, colour, className} = props

  const style = {
    width: `${width}px`,
    height: `${height}px`,
    backgroundColor: `${colour}`,
    display: 'inline-block',
    borderRadius: '50%'
  }

  return <Box className={className} sx={style} onClick={props.onClick}></Box>
}

export default Circle
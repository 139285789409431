const useToolTipStyle = (variant) => {

  return {
    container: {
      position: 'relative',
      display: 'inline-block',
    },
    mark: {
      width: '15px',
      height: '15px',
      backgroundColor: 'primary',
      color: 'black',
      fontSize: '10px',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '50%',
      cursor: 'pointer',
      fontWeight: 700,
    },
    content: {
      backgroundColor: 'primary',
      p: '10px',
      borderRadius: '8px',
      fontSize: '12px',
      lineHeight: 'body',
    },
    tip: {
      width: '240px',
      position: 'absolute',
      top: '0px',
      left: variant === 'left'
        ? 'auto'
        : '-17px',
      right: variant === 'left'
        ? '-17px'
        : 'auto',
      transform: 'translateY(calc(-100% - 5px))',
      pb: '8px',
    },
    triangle: {
      position: 'absolute',
      bottom: '0px',
      left: variant === 'left'
        ? 'auto'
        : '24px',
      right: variant === 'left'
        ? '14px'
        : 'auto',
      transform: 'rotate(90deg)',
      transformOrigin: 'left center'
    }
  }
}

export default useToolTipStyle
/** @jsx jsx */
import { useContext } from 'react'
import { jsx } from 'theme-ui'
import { Inline } from 'raam'
import { Link } from 'gatsby'
import { onClickGtag } from '../../utils/gtag'
import MenuContext from './context'

const DesktopMenu = () => {
  const context = useContext(MenuContext)

  const NavItem = x => {
    if (!x.customLink && !x.page) return null

    const current = context.getCurrent(context.nav, context.path)
    const active = current?.id === x.id

    return (
      <Link
        to={context.getLink(x)}
        sx={{ variant: 'links.nav', fontWeight: active ? 700 : 400 }}
        onClick={() => onClickGtag('click', 'Desktop Menu', x.name)}
      >
        {x.name}
      </Link>
    )
  }

  return (
    <Inline as="ul" gap={6} sx={{ overflow: 'visible' }}>
      {context.nav?.map((x, i) => {
        return <NavItem {...x} key={i} />
      })}
    </Inline>
  )
}

export default DesktopMenu

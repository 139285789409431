import React from 'react'
import logo from '../../images/fgr-logo.png';
import { Link } from 'gatsby'

const Logo = (props) => {

  return (
    <Link to='/' {...props}>
      <img
        src={logo}
        alt="Forest Green Rovers"
      />
    </Link>
  )
}

export default Logo
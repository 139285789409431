/** @jsx jsx */
import { jsx, Grid, Box, AspectImage } from 'theme-ui'
import { useState } from 'react'

const Gallery = props => {
  const { images } = props
  const [activeImage, setActiveImage] = useState(
    images.length && images?.find((_, i) => i === 0).large
  )

  const imageSX = {
    width: '100%',
    height: '100%',
  }

  return (
    <Box className={props.className}>
      {activeImage && (
        <AspectImage ratio={1 / 1} sx={imageSX} src={activeImage} alt="" />
      )}
      <Grid columns="repeat(4, 1fr)" mt={4}>
        {images?.map((x, i) => {
          return (
            <AspectImage
              key={i}
              ratio={1 / 1}
              sx={{ ...imageSX, cursor: 'pointer' }}
              src={x.thumbnail}
              onClick={() => setActiveImage(x.large)}
            />
          )
        })}
      </Grid>
    </Box>
  )
}

export default Gallery

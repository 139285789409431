/** @jsx jsx */
import { jsx, Box, Text } from 'theme-ui'
import { useContext } from 'react'
import { responsive } from '../../utils/helpers'
import Wrapper from '../layout/wrapper'
import Inner from '../layout/inner'
import { Wrap } from 'raam'
import { graphql, useStaticQuery, Link } from 'gatsby'
import MenuContext from '../menu/context'

const Nav = () => {
  const context = useContext(MenuContext)

  const data = useStaticQuery(graphql`
    query FooterNavigation {
      contentfulNavigation(title: { eq: "Footer" }) {
        title
        menu {
          id
          customLink
          name
          contentfulchildren {
            id
            customLink
            name
            page {
              ... on ContentfulPage {
                __typename
                slug
              }
              ... on ContentfulTeam {
                __typename
                slug
              }
            }
            newsCategory {
              ... on ContentfulNewsCategories {
                __typename
                slug
              }
            }
          }
          page {
            ... on ContentfulPage {
              __typename
              slug
            }
            ... on ContentfulTeam {
              __typename
              slug
            }
          }
        }
      }
    }
  `)

  const containerSX = {
    width: '100%',
    backgroundColor: 'black',
    padding: '75px 0 25px',
  }

  const addressSX = {
    fontSize: 1,
    color: 'white',
    textAlign: 'center',
    marginBottom: responsive(5, 2),
  }

  const wrapSX = {
    justifyContent: 'center',
  }

  const itemSX = {
    listStyle: 'none',

    a: {
      color: 'white',
      textDecoration: 'none',
    },
  }

  const NavItem = x => {
    if (!x.customLink && !x.page) return null

    // const current = context.getCurrent(context.nav, context.path)
    // const active = current?.id === x.id

    return (
      <Box as="li" sx={itemSX}>
        <Link to={context.getLink(x)}>{x.name}</Link>
      </Box>
    )
  }

  return (
    <Box sx={containerSX}>
      <Wrapper>
        <Inner>
          <Text sx={addressSX}>The New Lawn, Nailsworth, GL6 0FG</Text>
          <Wrap sx={wrapSX} gap={7}>
            {data.contentfulNavigation?.menu?.map(x => (
              <NavItem {...x} key={x.id} />
            ))}
          </Wrap>
        </Inner>
      </Wrapper>
    </Box>
  )
}

export default Nav

import React, { useState, useEffect, useCallback } from 'react';
import { ThemeProvider } from 'theme-ui'
import { theme } from '../../styles/theme'
import { Global, css } from '@emotion/core'
import emotionReset from 'emotion-reset';
import Header, { HeaderContext } from './../header';
import Footer from '../footer';
import '../../styles/fonts.css'

const Layout = (props) => {

  const [headerHeight, setHeaderHeight] = useState(0)
  const [headerRef, setHeaderRef] = useState(false)
  const [windowWidth, setWindowWidth] = useState(0)
  const backgroundColor = theme.colors.background

  const handleWindowWidthChange = () => {
    setWindowWidth(window.innerWidth);
  }

  const handleHeaderHeight = useCallback(() => {
    if (!headerRef)
      return
    setHeaderHeight(headerRef.current.offsetHeight)
  }, [headerRef])

  useEffect(() => {
    handleHeaderHeight()
    window.addEventListener('resize', handleWindowWidthChange)
    return () => {
      window.removeEventListener('resize', handleWindowWidthChange);
    }
  }, [handleHeaderHeight])

  useEffect(() => {
    handleHeaderHeight()
  }, [windowWidth, headerRef, handleHeaderHeight])

  return (
    <ThemeProvider theme={theme}>
      <Global styles={theme => (css`
        ${emotionReset}
        html {
          font-family: ${theme.fonts.body};
        }
        body {
          background: ${props.light ? 'white' : backgroundColor}
        }
        body.no-scroll {
          height: 100vh;
          width: 100vw;
          overflow: hidden;
          position: fixed;
        }
      `)} />
      
      <HeaderContext.Provider value={{height: headerHeight, setHeaderRef: setHeaderRef}}>
        <Header {...props} />
          {props.children}
        {!props.plain &&
          <Footer {...props} />
        }
      </HeaderContext.Provider>
    </ThemeProvider>
  )
}

export default Layout

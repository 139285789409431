/** @jsx jsx */
import { jsx, Text, Box, Flex } from 'theme-ui';
import Wrapper from '../layout/wrapper'
import Ecotricity from '../../images/ecotricity-white.png'
import { useMediaQuery } from 'react-responsive'

const TopBar = () => {

  const isDesktop = useMediaQuery({ query: '(min-width: 1241px)' })

  const containerSX = {
    width: '100%',
    height: '38px',
    backgroundColor: 'black',
    position: 'relative',
    zIndex: 200
  }

  const innerSX = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '38px',
    width: '100%',
  }

  const textSX = {
    color: 'white',
    fontSize: 0,

    'strong': {
      color: 'primary',
      textTransform: 'uppercase',
      fontWeight: 700,
      mx: '5px',
    }
  }

  const leftSX = {
    ...textSX,
  }

  const rightSX = {
    ...textSX,
    textAlign: 'right',
    alignItems: 'center',
    textDecoration: 'none'
  }

  const imageSX = {
    width: '115px',
    height: 'auto',
    objectFit: 'contain',
    marginLeft: 1
  }

  return (
    <Box sx={containerSX}>
      <Wrapper sx={innerSX}>
        {isDesktop &&
          <Text sx={leftSX}>
            Forest Green Rovers, The World's Greenest Football Club
          </Text>
        }
        <Flex sx={rightSX} as="a" target="_blank" href="https://www.ecotricity.co.uk/">Powered by <img src={Ecotricity} sx={imageSX} alt="Ecotricity" /></Flex>
      </Wrapper>
    </Box>
  )
}

export default TopBar
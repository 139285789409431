/** @jsx jsx */
import { jsx, Flex, Text } from 'theme-ui';
import { useState, useEffect } from 'react'

import Arrow from '../../icons/arrow-back.svg'

const NumberPicker = (props) => {

  const { onChange } = props

  const [selected, setSelected] = useState(props.default ? props.default : 0)

  useEffect(() => {
    if (onChange)
      onChange(selected)
  }, [onChange, selected])

  const containerSX = {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '50px',
    height: '75px',
    backgroundColor: 'white',
    textAlign: 'center',
    padding: '5px',
  }

  const arrow = {

  }

  const arrowUp = {
    ...arrow,
    transform: 'rotate(90deg)',
  }

  const arrowDown = {
    ...arrow,
    transform: 'rotate(-90deg)',
  }

  const number = {
    fontFamily: 'heading',
    fontSize: 8,
  }

  const arrowUpClick = () => {
    if (selected === 9) {
      setSelected(0)
    } else {
      setSelected(selected+1)
    }
  }

  const arrowDownClick = () => {
    if (selected === 0) {
      setSelected(9)
    } else {
      setSelected(selected-1)
    }
  }

  return (
    <Flex className={props.className} sx={containerSX}>
      <Arrow sx={arrowUp} onClick={arrowUpClick} />
      <Text sx={number}>{selected}</Text>
      <Arrow sx={arrowDown} onClick={arrowDownClick} />
    </Flex>
  )
}

export default NumberPicker
export const selectStyles = {
  indicatorSeparator: () => ({display: 'none' }),
  control: (provided) => ({
    ...provided, 
    borderRadius: '8px',
    border: 'none',
    boxShadow: '0 0 4px rgba(0, 0, 0, 0.125)',
    height: '58px'
  }),
  placeholder: (provided) => ({
    ...provided,
    // color: 'black'
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: 'black'
  }),
}

export const selectStylesBold = {
  indicatorSeparator: () => ({display: 'none' }),
  control: (provided) => ({
    ...provided, 
    borderRadius: '4px',
    border: '1px solid black',
    height: '48px'
  }),
  placeholder: (provided) => ({
    ...provided,
    color: 'black',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: 'black'
  }),
  singleValue: (provided) => ({
    ...provided,
    fontFamily: '"Zuume Bold", sans-serif',
    fontSize: '24px'
  })
}
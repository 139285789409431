/** @jsx jsx */
import { jsx } from 'theme-ui';
import React from 'react'
import Partners from './partners'
import Forum from './forum'
import Social from './social'
import Nav from './nav'
import Powered from './powered'
// import { graphql, useStaticQuery } from 'gatsby'
// import Wrapper from '../layout/wrapper'
// import Inner from '../layout/inner'

const Footer = (props) => {

  // const query = useStaticQuery(graphql`
  //   query footer {
  //     contentfulFooter {
  //       promo {
  //         ...PromoFragment
  //       }
  //     }
  //   }
  // `)

  return (
    <React.Fragment>
      
      {/* <Wrapper>
        <Inner>
          <Promo />
        </Inner>
      </Wrapper> */}
      
      <Partners {...props} />
      <Forum {...props} />
      <Social {...props} />
      <Nav {...props} />
    </React.Fragment>
  )
}

export default Footer
export { Powered }
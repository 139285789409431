import Zebra from '../../images/zebra.png'
import { responsive } from '../../utils/helpers'

const layout = {
  justifyContent: 'center',
  flexWrap: 'wrap',
  pt: 7,
}

const style = {
  zebra: {
    background: `url(${Zebra}) repeat-x transparent`,
    backgroundSize: '1440px 32px',
    width: '100%',
    height: '32px',
  },
  wrapper: {
    backgroundColor: 'white',
    paddingTop: 11,
    paddingBottom: 3,
  },
  title: {
    fontSize: 5,
    fontWeight: 700,
    marginBottom: '10px',
    textAlign: 'center'
  },
  layout: layout,
  rowOne: {
    ...layout,

    'img': {
      maxWidth: '200px',
      mx: 8,
      mb: responsive(1, 8),
      objectFit: 'contain'
    }
  },
  rowTwo: {
    ...layout,
    pt: 0,

    'img': {
      maxWidth: '160px',
      mx: 5,
      mb: responsive(2, 5),
      objectFit: 'contain'
    }
  },
  border: {
    borderBottom: '1px solid',
    borderColor: 'background'
  }
}

export default style
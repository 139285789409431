import React from 'react';

const getLink = (x) => {
  return x.customLink 
    ? x.customLink 
    : x.newsCategory 
      ? `/news/category/${x.newsCategory.slug}` 
      : `/${x.page?.__typename === 'ContentfulTeam' ? 'team/' : ''}${x.page?.slug}` 
}

const isActive = (x, path) => {
  const to = getLink(x)
  return trim(to) === trim(path)
}

const isParent = (x, path) => {
  const firstSegment = path.split('/')[1];
  const to = getLink(x)
  return trim(firstSegment) === trim(to)
}

const getCurrent = (nav, path) => {
  const firstSegment = path?.split('/')[1]
  const isTeam = firstSegment === 'team'
  
  return nav.find((x) => {
    const parentLink = getLink(x)
    if (parentLink === `/${firstSegment}` || path === `${parentLink}/` || parentLink === path || isTeam && x.page?.__typename === 'ContentfulTeam') {
      return true
    } else {
      return x.contentfulchildren?.findIndex((x) => {
        const link = getLink(x)
        return link === `/${firstSegment}`.match || path === `${link}/` || path === link
      }) >= 0
    }
  })
}

const trim = (string, char = '/') => {
  const r = rtrim(string, char)
  const l = ltrim(r, char)
  return l
}

const rtrim = (string, char = '/') => {
  return string.endsWith(char) ?
    string.slice(0,-1) :
    string
}

const ltrim = (string, char = '/') => {
  return string.startsWith(char) ?
    string.slice(1) :
    string
}

const MenuContext = React.createContext({open: false, isActive: isActive, isParent: isParent, getLink: getLink, getCurrent: getCurrent})

export default MenuContext

const style = {

  error: {
    color: 'red',
    textAlign: 'left',
    pt: 2,
  }
}

export default style
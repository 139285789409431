/** @jsx jsx */
import { jsx, Box, Text, Label } from 'theme-ui';

const Radio = (props) => {

  const style = {
    position: 'relative',
    cursor: 'pointer',
    fontSize: '22px',
    userSelect: 'none',
    paddingLeft: '44px',
    display: 'flex',
    alignItems: 'center',
    height: '34px',
    // width: '0',
    
    'input': {
      display: 'none'
    },

    '.checkmark': {
      position: 'absolute',
      top: '0px',
      left: '0px',
      height: '34px',
      width: '34px',
      border: '1px solid',
      borderColor: 'black',
      borderRadius: '50%',
      backgroundColor: props.backgroundColour ? props.backgroundColour : 'transparent'
    },

    'input:checked ~ .checkmark': {

      '&:after': {
        content: '""',
        width: '24px',
        height: '24px',
        backgroundColor: props.activeColour ? props.activeColour : 'primary',
        position: 'absolute',
        left: '4px',
        top: '4px',
        borderRadius: '50%'
      }
    }
  }

  const label = {
    fontSize: 0,

  }

  return (
    <Label sx={style} className={props.className}>
      <Text sx={label} className="label">{props.label}</Text>
      <input type="radio" name={props.name} value={props.value} onChange={props.onChange} checked={props.checked} />
      <Box className="checkmark" />
    </Label>
  )
}

export default Radio
/** @jsx jsx */
import { jsx, Box, Flex, useThemeUI, get} from 'theme-ui';
import { useState } from 'react'
import { Triangle } from '../shapes'
// import style from './tooltip.style'
import useToolTipStyle from './useToolTipStyle'

const ToolTip = (props) => {
  
  const { theme } = useThemeUI()
  const [ show, setShow ] = useState(false)
  const style = useToolTipStyle(props.variant)

  return (
    <Box sx={style.container} className={props.className}>
      <Flex sx={style.mark} onClick={() => setShow(!show)}>?</Flex>
      {show &&
        <Box sx={style.tip}>
          <Box sx={style.content}>
            {props.children}
            <Triangle 
              width={10} 
              height={17} 
              colour={get(theme, 'colors.primary')} 
              sx={style.triangle} 
            />
          </Box>
        </Box>
      }
    </Box>
  )
}

export default ToolTip
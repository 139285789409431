import React, { useState } from 'react'
import { Box, Input, Text, Button } from 'theme-ui'

const OutOfStock = props => {
  const [email, setEmail] = useState('')
  const [formSubmitted, setFormSubmitted] = useState(false)

  const handleSubmit = async e => {
    e.preventDefault()

    const outOfStockForm = document.getElementById('out-of-stock-form')

    const requestOptions = {
      method: 'GET',
    }

    const productId = Buffer.from(props.productId, 'base64')
      .toString('utf-8')
      .split('gid://shopify/Product/')[1]
    const variantId = Buffer.from(props.variantId, 'base64')
      .toString('utf-8')
      .split('gid://shopify/ProductVariant/')[1]

    fetch(
      `https://app.backinstock.org/stock_notification/create.json?shop=${process.env.GATSBY_SHOP_NAME}.myshopify.com&notification[email]=${email}&notification[product_no]=${productId}&notification[quantity_required]=${props.quantity}&notification[customer_utc_offset]=3600&variant[variant_no]=${variantId}`,
      requestOptions
    )
      .then(() => {
        setFormSubmitted(true)
        outOfStockForm.reset()
      })
      .catch(error => console.log('error', error))
  }

  return (
    <Box as="form" id="out-of-stock-form" onSubmit={handleSubmit}>
      <Text as="p" sx={{ lineHeight: 'body', mb: '15px' }}>
        Sorry, this product is temporarily out of stock, however you can
        register your email below to receive an email when this product becomes
        available again.
      </Text>
      <Input
        type="email"
        placeholder="Enter email address"
        required={true}
        onChange={e => setEmail(e.target.value)}
      />
      <Button mt={'15px'} type="submit" disabled={formSubmitted}>
        {!formSubmitted ? 'Submit' : 'Thanks'}
      </Button>
    </Box>
  )
}

export default OutOfStock

import { responsive } from '../../utils/helpers'

export const top = '74px';

const link = {
  color: 'black',
  fontSize: 1,
  textDecoration: 'none',
}

const linkActive = {
  fontWeight: 700
}

const style = {
  
  container: {
    justifyContent: 'center',
    backgroundColor: 'background',
    borderColor: 'greyborderlight',
    position: 'relative',
    zIndex: 199,
    py: responsive(1, 3),
  },
  list: {
    py: responsive(7, 0, 8),
    px: responsive('50px', '183px', 8),
    position: responsive('absolute', 'static', 8),
    top: top,
    backgroundColor: responsive('background', 'transparent', 8),
    left: 0,
    zIndex: 1,
    width: '100%',
    transition: 'all 0.2s linear',
    borderTop: responsive('1px solid', 'none', 8),
    borderColor: 'greybordermenu',
    textAlign: 'center',
  },
  link: link,
  linkActive: linkActive,
  toggle: {
    justifyContent: 'flex-end',
    paddingTop: 3,
    paddingBottom: 3,
    paddingLeft: responsive('50px', '183px', 8),
    zIndex: 2,
    position: 'relative',
  },
  toggleLink: {
    ...link, 
    ...linkActive,
    cursor: 'pointer'
  }
}

export default style
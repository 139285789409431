/** @jsx jsx */
import { useContext } from 'react'
import { jsx, Box } from 'theme-ui'
import MenuContext from './context'

const Burger = () => {

  const context = useContext(MenuContext)

  const line = {
    position: 'absolute',
    width: '100%',
    background: 'black',
    height: '2px',
    left: '0px'
  }

  const container = {
    width: '21px',
    height: '14px',
    position: 'relative'
  }

  const lineOne = {
    top: context.open ? '6px' : '0px',
    transform: context.open ? 'rotate(45deg)' : 'rotate(0deg)',
    transition: 'all 0.2s linear'
  }

  const lineTwo = {
    top: '6px',
    opacity: context.open ? '0' : '100%',
    transition: 'opacity 0.2s linear'
  }

  const lineThree = {
    bottom: context.open ? '6px' : '0px',
    transform: context.open ? 'rotate(-45deg)' : 'rotate(0deg)',
    transition: 'all 0.2s linear'
  }

  return (
    <Box onClick={context.onClick} sx={container}>
      <Box as="span" sx={{...line, ...lineOne}} />
      <Box as="span" sx={{...line, ...lineTwo}} />
      <Box as="span" sx={{...line, ...lineThree}}/>
    </Box>
  )
}

export default Burger
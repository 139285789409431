/** @jsx jsx */
import { useState, useRef, useEffect, useContext } from 'react'
import { jsx, Flex, Box } from 'theme-ui'
import Logo from './logo';
import { DesktopMenu, MobileMenu, UtilityMenu, SubMenu, MenuContext } from './../menu';
import Wrapper from './../layout/wrapper';
import { useMediaQuery } from 'react-responsive'
import HeaderContext from './context'
import TopBar from './topbar'
import { graphql, useStaticQuery } from 'gatsby'
import { CollectionsMenu } from '../shop'
import { lockBody, releaseBody } from '../../utils/helpers'
// import { gsap } from "gsap";
// import { ScrollToPlugin } from "gsap/ScrollToPlugin";

const Header = (props) => {


  const [menuOpen, setMenuOpen] = useState(false)
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1240px)' })
  const isDesktop = useMediaQuery({ query: '(min-width: 1241px)' })
  
  const context = useContext(HeaderContext)
  const menuContext = useContext(MenuContext)
  const headerRef = useRef()

  const logoStyle = {
    width: ['100px', null, null, null, null, null, null, '138px'],
    height: ['100px', null, null, null, null, null, null, '138px'],
    marginRight: '45px',
    position: 'absolute',
    bottom: ['-48px', null, null, null, null, null, null, '-70px'],
    left: '0px',
    img: {
      width: '100%',
      height: '100%'
    },
    zIndex: 2
  }

  const headerOuter = {
    height: ['65px', null, null, null, null, null, null, '100px'],
    background: 'white',
    alignItems: 'stretch',
    position: 'relative',
    zIndex: 200
  }

  const headerInner = {
    alignItems: 'center',
    paddingLeft: ['130px', null, null, null, null, null, null, '183px'],
    position: 'relative',
    height: '100%'
  }

  const onMenuClick = () => {
    if (menuOpen) {
      releaseBody()
    } else {
      // gsap.to(window, {duration: 0.3, scrollTo: 0});
      lockBody()
    }
    setMenuOpen(!menuOpen)
  }

  useEffect(() => {
    context.setHeaderRef(headerRef)
  }, [context])

  const data = useStaticQuery(graphql`
    query MainNavigation {
      contentfulNavigation(title: {eq: "Main"}) {
        title
        menu {
          id
          customLink
          name
          contentfulchildren {
            id
            customLink
            name
            page {
              ... on ContentfulPage {
                __typename
                slug
              }
              ... on ContentfulTeam {
                __typename
                slug
              }
            }
            newsCategory {
              ... on ContentfulNewsCategories {
                __typename
                slug
              }
            }
          }
          page {
            ... on ContentfulPage {
              __typename
              slug
            }
            ... on ContentfulTeam {
              __typename
              slug
            }
          }
        }
      }
    }
  `)

  return (
    <MenuContext.Provider value={{...menuContext, nav: data?.contentfulNavigation?.menu, onClick: onMenuClick, open: menuOpen, path:props.path}}>
      <TopBar />
      <Box ref={headerRef}>
        {!props.noMenu &&
          <Flex sx={headerOuter}>
            <Wrapper>
              <Flex as="header" sx={headerInner}>
                <Logo sx={logoStyle} />
                <Box sx={{flexGrow: 1}}>
                  {isTabletOrMobile &&
                    <MobileMenu />
                  }
                  {isDesktop &&
                    <DesktopMenu />
                  }
                </Box>
                <UtilityMenu />
              </Flex>
            </Wrapper>
          </Flex>
        }
        
        <SubMenu />
        {props.path.startsWith('/shop') &&
          <CollectionsMenu {...props} />
        }
      </Box>
    </MenuContext.Provider>
  )
}

export default Header
export { Logo, HeaderContext }
/** @jsx jsx */
import { jsx } from 'theme-ui'
import { useContext, useMemo, useEffect, useState } from 'react'
import Variant from './variant'
import StoreContext from '../../contexts/storeContext'
import { prepareVariantsWithOptions } from '../../utils/shop'

const Customisation = props => {
  const [currentVariant, setCurrentVariant] = useState({})
  const [shopifyVariant, setShopifyVariant] = useState()
  const [available, setAvailable] = useState()
  const [price, setPrice] = useState(0)
  const storeContext = useContext(StoreContext)
  const storeHelpers = storeContext.store.client.product.helpers

  const variants = useMemo(() => {
    const v = prepareVariantsWithOptions(props.variants)
    return v.map(x => ({
      ...x,
      options: [{ value: false, label: 'None' }, ...x.options],
    }))
  }, [props.variants])

  const handleVariantChange = (name, value) => {
    if (!name) return
    const current = { ...currentVariant, [name]: value }
    setCurrentVariant(current)
  }

  useEffect(() => {
    const variant = storeHelpers.variantForOptions(props, currentVariant)
    setShopifyVariant(variant)
    setAvailable(variant?.availableForSale)
    setPrice(variant?.presentmentPrices[0].price.amount)
    props.onChange &&
      props.onChange({
        product: props.shopifyId,
        variant: variant,
      })
  }, [currentVariant, storeHelpers])

  return variants?.map((x, i) => {
    return (
      <Variant
        key={i}
        {...x}
        onChange={e => handleVariantChange(x.name, e)}
        onCustomNameChange={props.onCustomNameChange}
        customName={props.customName}
        // handleCustomNameChange={handleCustomNameChange}
        // handlePlayerNameChange={e => setPlayerName(e)}
        // handleCustomMessageChange={e => setCustomMessage(e)}
        // customName={customName}
        // playerName={playerName}
        // customMessage={customMessage}
        selected={currentVariant[x.name]}
      />
    )
  })
}

export default Customisation

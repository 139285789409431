/** @jsx jsx */
import { useState, useEffect } from 'react'
import { jsx } from 'theme-ui';
import { Circle } from '../shapes'
import { Inline } from 'raam'

const Progress = (props) => {

  const [active, setActive] = useState(props.active ? props.active : 0)
  const activeColour = props.activeColour ? props.activeColour : 'primary'
  const inactiveColour = props.inactiveColour ? props.inactiveColour : 'black'

  const circleSX = {
    cursor: props.noInteraction ? 'default' : 'pointer'
  }

  const clickHandler = (i) => {
    if (props.noInteraction)
      return

    if (props.onClick) {
      props.onClick(i)
      return
    }

    setActive(i)
  }

  useEffect(() => {
    setActive(props.active)
  }, [props.active])

  const Dot = ({i}) => {
    return <Circle sx={circleSX} width={10} height={10} colour={i === active ? activeColour : inactiveColour} onClick={() => {clickHandler(i)}}/>
  }

  return (
    <Inline className={props.className} sx={{overflow: 'visible'}}>
      {[...Array(props.steps)].map((x,i) => {
        return <Dot i={i} key={i} />
      })}
    </Inline>
  )
}

export default Progress
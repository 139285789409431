/** @jsx jsx */
import { jsx } from 'theme-ui'
import { responsive } from '../../utils/helpers'

const Wrapper = (props) => {
  
  const Tag = props.as ? props.as : 'div';

  const sx = {
    maxWidth: '1425px',
    width: '100%',
    margin: '0 auto',
    padding: responsive('0 25px', '0 40px')
  }

  return (
    <Tag sx={sx} {...props}>
      {props.children}
    </Tag>
  )
}

export default Wrapper;
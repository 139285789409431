/** @jsx jsx */
import { useState } from 'react'
import { jsx, Grid } from 'theme-ui'
import { responsive } from '../../utils/helpers'
import Select from 'react-select'
import { selectStyles } from '../../styles/select'
import moment from 'moment'

export const months = ["January","February","March","April","May","June","July", "August","September","October","November","December"];

const DateSelect = (props) => {

  const now = new Date().getUTCFullYear(); 
  const years = Array(now - (now - 90)).fill('').map((_, i) => now - i);
  const yearOptions = years.map(x => ( {value: x, label: x } ))
  const monthOptions = months.map(x => ( {value: x, label: x } ))
  const days =  Array(31).fill().map((_, i) => 1 + i)
  const dayOptions = days.map(x => ( {value: x, label: x } ))

  const getDefaults = () => {
    const blank = {
      day: false,
      month: false,
      year: false,
    }

    if (!props.default) return blank
    const m = moment(props.default, 'D MMMM YYYY');
    if (!m.isValid()) return blank
    const day = m.format('D');
    const month = m.format('MMMM');
    const year = m.format('YYYY');
    return {
      day: {value: day, label: day},
      month: {value: month, label: month},
      year: {value: year, label: year},
    }
  }

  const defaults = getDefaults()
  const [day, setDay] = useState(defaults.day)
  const [month, setMonth] = useState(defaults.month)
  const [year, setYear] = useState(defaults.year)

  const handleChange = (option, type) => {
    const set = type === 'day' 
      ? setDay 
      : type === 'month' 
        ? setMonth 
        : setYear
    set(option)
    if (props.onChange) {
      const dayValue = type === 'day' ? option.value : day.value;
      const monthValue = type === 'month' ? option.value : month.value;
      const yearValue = type === 'year' ? option.value : year.value;
      props.onChange(dayValue, monthValue, yearValue)
    }
  }

  return (
    <Grid columns={responsive(1, 3)}>
      <Select 
        styles={selectStyles}
        isSearchable={false}
        placeholder='Day'
        options={dayOptions} 
        onChange={(e) => handleChange(e, 'day')}
        value={day}
      />
      <Select 
        styles={selectStyles}
        isSearchable={false}
        placeholder='Month'
        options={monthOptions} 
        onChange={(e) => handleChange(e, 'month')}
        value={month}
      />
      <Select 
        styles={selectStyles}
        isSearchable={false}
        placeholder='Year'
        options={yearOptions}
        onChange={(e) => handleChange(e, 'year')}
        value={year}
      />
    </Grid>
  )
}

export default DateSelect
/** @jsx jsx */
import React, { useState } from 'react'
import { jsx, Input, Text } from 'theme-ui';
import styles from './customAttributeInput.style'

const Error = ({message}) => {
  if (!message) return null

  return <Text sx={styles.error}>{message}</Text>
}

const CustomAttributeInput = (props) => {

  const [value, setValue] = useState(props.value ? props.value : '')
  const [error, setError] = useState()

  const handleChange = (e) => {
    if (props.characterLimit && e.target.value.length > props.characterLimit) {
      setError(`No more than ${props.characterLimit} characters allowed`)
      return
    }
    setError(false)
    setValue(e.target.value)
    props.onChange && props.onChange(e.target.value)
  }

  return (
    <React.Fragment>
      <Input 
        onChange={handleChange} 
        value={value} 
        placeholder={props.placeholder} 
        mt={4}
      />
      <Error message={error} />
    </React.Fragment>
  )
}

export default CustomAttributeInput
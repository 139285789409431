/** @jsx jsx */
import { jsx, Box, Button, Text, Grid } from 'theme-ui';
import Wrapper from '../layout/wrapper'
import Inner from '../layout/inner'
import Logo from '../../images/fgr-logo.png'
import { responsive } from '../../utils/helpers'
import { Wrap } from 'raam'
import Twitter from '../../icons/twitter-brands.svg'
import Facebook from '../../icons/facebook-f-brands.svg'
import Instagram from '../../icons/instagram-brands.svg'
import LinkedIn from '../../icons/linkedin-in-brands.svg'
import Pinterest from '../../icons/pinterest-p-brands.svg'
import YouTube from '../../icons/youtube-brands.svg'

const Social = () => {

  const containerSX = {
    backgroundColor: "black",
    padding: responsive('70px 0', '30px 0'),
    position: 'relative'
  }

  const logoSX = {
    width: responsive('80px', '138px'),
    height: responsive('80px', '138px'),
    position: 'absolute',
    top: responsive('-40px', '-69px'),
    left: '50%',
    transform: 'translateX(-50%)',
  }

  const tagsSX = {
    width: '100%',
    marginBottom: responsive(6, 10),
  }

  const tagSX = {
    color: 'white',
    fontSize: responsive(10, 11),

    'a': {
      color: 'inherit',
      textDecoration: 'none',
    }
  }

  const tagOneSX = {
    ...tagSX,
    textAlign: responsive('center', 'right')
  }

  const tagTwoSX = {
    ...tagSX,
    textAlign: responsive('center', 'left')
  }

  const titleSX = {
    fontSize: 5,
    fontWeight: 700,
    textAlign: 'center',
    color: 'white',
    marginBottom: 5
  }

  const buttonContainerSX = {
    justifyContent: 'center',
  }

  const buttonSX = {
    backgroundColor: 'primary',
    color: 'black',
    padding: '5px',
    transition: 'all 0.2s linear',

    '> svg': {
      width: '100%',
    },

    '&:hover': {
      backgroundColor: 'white'
    }
  }

  const facebookSX = {

    '> svg': {
      width: '19px',
      position: 'relative',
      bottom: '-4px'
    }
  }

  const linkedinSX = {

    '> svg': {
      width: '19px'
    }
  }

  const pinterestSX = {

    '> svg': {
      width: '22px',
      position: 'relative',
      bottom: '-4px'
    }
  }

  return (
    <Box sx={containerSX}>
      <Wrapper>
        <Inner>
          <img sx={logoSX} src={Logo} alt="FGR" />
          <Grid sx={tagsSX} columns={responsive('1', '1fr 240px 1fr')} gap={0}>
            <Text variant="heading" sx={tagOneSX}><a href="/">@FGRFC_Official</a></Text>
            <Box sx={{display: responsive('none', 'block')}}></Box>
            <Text variant="heading" sx={tagTwoSX}><a href="/">#WeAreFGR</a></Text>
          </Grid>
          <Text sx={titleSX}>Join the conversation</Text>
          <Wrap gap={7} sx={buttonContainerSX}>
            <Button variant="icon" sx={buttonSX} as="a" target="_blank" href="https://twitter.com/FGRFC_Official"><Twitter /></Button>
            <Button variant="icon" sx={{...buttonSX, ...facebookSX}} as="a" target="_blank" href="https://www.facebook.com/fgrfc/"><Facebook /></Button>
            <Button variant="icon" sx={buttonSX} as="a" target="_blank" href="https://www.youtube.com/channel/UC9hiO4pAzKontGqMaakP-Fw"><YouTube /></Button>
            <Button variant="icon" sx={buttonSX} as="a" target="_blank" href="https://www.instagram.com/fgrfc_official/"><Instagram /></Button>
          </Wrap>
        </Inner>
      </Wrapper>
    </Box>
  )
}

export default Social
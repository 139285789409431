/** @jsx jsx */
import { jsx } from 'theme-ui';

const Triangle = ({width, height, colour, className}) => {

  const midHeight = height / 2;

  const style = {
    borderStyle: 'solid',
    borderColor: `transparent transparent transparent ${colour}`,
    borderWidth: `${midHeight}px 0 ${midHeight}px ${width}px`,
    display: 'inline-block',
    height: '0px',
    width: '0px'
  }

  return <div className={className} sx={style}></div>
}

export default Triangle
/** @jsx jsx */
import { jsx, Box, Button, Flex, Text } from 'theme-ui';
import ArrowBack from '../../images/arrow-back.svg'
import { responsive } from '../../utils/helpers';

const Forum = () => {

  const layoutSX = {
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    padding: responsive('0 25px', '0 40px')
  }

  const containerSX = {
    backgroundColor: 'primary',
    paddingTop: 9,
    paddingBottom: 17
  }

  const boldSX = {
    fontFamily: 'heading',
    fontSize: 10,
    lineHeight: 'compact',
    marginRight: 2,
    marginBottom: '0px',
    textAlign: 'center'
  }

  const lightSX = {
    fontFamily: 'light',
    fontSize: 10,
    lineHeight: 'compact',
    marginBottom: '0px',
    textAlign: 'center',
    marginRight: 2
  }

  const buttonSX = {
    backgroundColor: 'white',

    'img': {
      transform: 'rotate(180deg)'
    }
  }

  return (
    <Box sx={containerSX}>
      <Flex sx={layoutSX}>
        <Text sx={boldSX}>Join the official forum</Text><Text sx={lightSX}>of the world's greenest football club</Text>
        <Button as="a" href="https://forum.fgr.co.uk/" target="_blank" sx={buttonSX} variant="icon"><img src={ArrowBack} alt="Forum" /></Button>
      </Flex>
    </Box>
  )
}

export default Forum
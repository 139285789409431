/** @jsx jsx */
import { jsx, Text, Box, Flex, Input } from 'theme-ui';
import React, { useState, useEffect } from 'react'
import { Wrap } from 'raam'
import Name from './name'
import CustomAttributeInput from './customAttributeInput'
import { Radio } from '../form'
import Select from 'react-select'
import { selectStylesBold } from '../../styles/select'

const Variant = (props) => {

  const [selected, setSelected] = useState(props.selected)

  const containerSX = {
    borderBottom: '1px solid',
    borderColor: 'background',
    p: 4
  }

  const headingSX = {
    justifyContent: 'space-between'
  }

  const titleSX = {
    fontSize: 5,
    fontWeight: 700
  }

  const linkSX = {
    color: 'black',
  }

  const optionSX = {
    px: 3,
    py: 1,
    border: '1px solid',
    borderColor: 'black',
    borderRadius: '4px',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer'
  }

  const labelSX = {
    fontFamily: 'heading',
    fontSize: 7,
  }

  const { onChange } = props
  const firstOption = props.options[0]

  const handleClick = (value) => {
    setSelected(value)
    if (!onChange)
      return
    onChange(value)
  }

  useEffect(() => {
    handleClick(firstOption.value)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstOption])

  const Option = ({label, value}) => {
    return (
      <Flex sx={{...optionSX, backgroundColor: selected === value ? 'primary' : 'white'}} onClick={() => handleClick(value)}>
        <Text sx={labelSX}>{label}</Text>
      </Flex>
    )
  }

  const OptionButtons = () => {
    return (
      <Wrap pt={2} gap={'6px'}>
        {props.options.map((x,i) => {
          return <Option key={i} label={x.label} value={x.value} />
        })}
      </Wrap>
    )
  }

  const OptionRadios = () => {
    return (
      <Wrap mb={3} pt={4}>
        {props.options.map((x,i) => {
          return <Radio 
            key={i} 
            label={x.label} 
            name={`${props.name}-${x.label}`} 
            value={x.value} 
            checked={String(selected) === String(x.value)} 
            onChange={(e) => handleClick(e.target.value)} 
          />
        })}
      </Wrap>
    )
  }

  const handleCustomMessageChange = (value) => {
    if (value.length >= 201)
      return
    props.handleCustomMessageChange(value)
  }
  
  const selectOptions = props.options.map((x) => {return {value:x.value, label: x.label}})
  const showCustomMessage = props.name.toLowerCase() === 'signed copy'
  const showCustomName = String(selected).toLowerCase() === 'custom name & number'
  const showRadios = props.options.length <= 2
  const showButtons = props.options.length > 2 && props.options.length <= 5
  const showSelect = props.options.length > 5

  useEffect(() => {
    if (!showCustomName) {
      props.onCustomNameChange && props.onCustomNameChange(false)
    }
  }, [showCustomName])

  return (
    <Box sx={containerSX}>
      <Flex sx={headingSX}>
        <Text sx={titleSX}>{props.name}</Text>
        {props.help &&
          <Text as="a" href={props.help.url} sx={linkSX}>{props.help.label}</Text>
        }
      </Flex>
      {showCustomMessage &&
        <React.Fragment>
          <OptionRadios />
          <Input 
            onChange={(e) => handleCustomMessageChange(e.target.value)} 
            value={props.customMessage} 
            placeholder='Custom message (max 200 chars)'
            mt={4}
          />
        </React.Fragment>
      }
      {showRadios &&
        <OptionRadios />
      }
      {showButtons &&
        <OptionButtons />
      }
      {showSelect &&
        <Box pt={2}>
          <Select 
            styles={selectStylesBold}
            isSearchable={false}
            defaultValue={selectOptions[0]}
            options={selectOptions}
            onChange={({value}) => handleClick(value)}
          />
        </Box>
      }
      {showCustomName &&
        <CustomAttributeInput 
          onChange={props.onCustomNameChange}
          value={props.customName}
          characterLimit={12}
          placeholder='10. Smith'
        />
      }
    </Box>
  )
}

export default Variant
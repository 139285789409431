/** @jsx jsx */
import { jsx, Box, Input } from 'theme-ui';
import { selectStylesBold } from '../../styles/select'

const Quantity = (props) => {

  const containerSX = {
    borderBottom: '1px solid',
    borderColor: 'background',
    p: 4
  }

  return (
    <Box sx={containerSX}>
      <Box sx={{width: '140px'}}>
        <Input 
          styles={selectStylesBold}
          type='number'
          min="1"
          step="1"
          value={props.value}
          onChange={(e) => props.onChange(e.target.value)}
        />
      </Box>
    </Box>
  )
}

export default Quantity
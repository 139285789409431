export const prepareVariantsWithOptions = (variants) => {
  
  return variants.reduce((variants, variant) => {
    variant.selectedOptions.forEach(option => {
      const variantIndex = variants.findIndex(x => x.name === option.name)
      if (variantIndex < 0) {
        variants.push({
          name: option.name,
          options: [{
            value: option.value, 
            label: option.value
          }]
        })
      } else {
        const optionIndex = variants[variantIndex].options.findIndex(x => x.value === option.value)
        if (optionIndex < 0) {
          variants[variantIndex].options.push({
            value: option.value, 
            label: option.value
          })
        }
      }
    });
    return variants
  }, [])
}
/** @jsx jsx */
import { jsx, Flex } from 'theme-ui'
import { useContext, useEffect, useState } from 'react'
// import { use100vh } from 'react-div-100vh'
import { HeaderContext } from '../header'

const FullScreen = (props) => {

  const [minHeight, setMinHeight] = useState(0)
  const headerContext = useContext(HeaderContext)

  // const height = use100vh()

  useEffect(() => {
    // setMinHeight(`${height - headerContext.height}px`)
    setMinHeight(`calc(100vh - ${headerContext.height}px)`)
  }, [headerContext])

  const containerSX = {
    width: '100%',
    height: '100%',
    backgroundColor: props.backgroundColor ? props.backgroundColor : 'white',
    minHeight: minHeight,
    flexDirection: 'column',
    pb: 10
  }

  return (
    <Flex 
      className={props.className} 
      sx={containerSX} 
      as={props.as ? props.as : 'div'}
    >
      {props.children}
    </Flex>
  )
}

export default FullScreen;
/** @jsx jsx */
import { jsx } from 'theme-ui'
import { responsive } from '../../utils/helpers'

const Inner = (props) => {

  const Tag = props.as ? props.as : 'div';

  const sx = {
    width: '100%',
    padding: responsive('0', '0 50px'),
  }

  return (
    <Tag {...props} sx={sx}>
      {props.children}
    </Tag>
  )
}

export default Inner;
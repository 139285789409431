/** @jsx jsx */
import { useState, useContext } from 'react'
import { jsx, Flex, Text } from 'theme-ui'
import { Wrap, Stack } from 'raam';
import { Link } from 'gatsby'
import Wrapper from '../layout/wrapper'
import { useMediaQuery } from 'react-responsive'
import Arrow from '../../icons/arrow-back.svg'
import MenuContext from './context'
import style, { top } from './submenu.style'

const SubMenu = (props) => {

  const context = useContext(MenuContext);
  const [open, setOpen] = useState(false);
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1240px)' })
  const isDesktop = useMediaQuery({ query: '(min-width: 1241px)' })
  const List = isTabletOrMobile ? Stack : Wrap
  const current = context.getCurrent(context.nav, context.path)

  const NavItem = (x) => {

    if (!x.customLink && !x.page && !x.newsCategory)
      return null
    
    const active = context.isActive(x, context.path)
    const sx = active ? {...style.link, ...style.linkActive} : style.link

    return (
      <Link to={context.getLink(x)} sx={sx}>{x.name}</Link>
    )
  }

  const CustomNavItem = (x) => {
    const active = x.active
    const sx = active ? {...style.link, ...style.linkActive} : style.link
    return (
      <Link to={x.url} sx={sx}>{x.label}</Link>
    )
  }

  if (!current?.contentfulchildren && !props.items)
    return null

  return (
    <Flex sx={style.container}>
      <Wrapper>
        {isTabletOrMobile &&
          <Flex 
            sx={{
              ...style.toggle,
              ' svg': {
                transform: open ? 'rotate(90deg)' : 'rotate(-90deg)',
                ml: 3
              }
            }}
          >
            <Text sx={style.toggleLink} onClick={() => setOpen(!open)}>{current.name}<Arrow /></Text>
          </Flex>
        }
        <List 
          sx={{
            ...style.list,
            transform: open || isDesktop ? 'translateY(0)' : `translateY(calc(-100% - ${top}))`,
          }} 
          gap={7}
        >
          {current?.contentfulchildren?.map((x,i) => {
            return <NavItem key={i} {...x} />
          })}
          {props.items?.map((x,i) => {
            return <CustomNavItem key={i} {...x} />
          })}
        </List>
      </Wrapper>
    </Flex>
  )
}

export default SubMenu
/** @jsx jsx */
import { useContext } from 'react'
import { jsx, Flex } from 'theme-ui'
import { Link } from 'gatsby'
import { onClickGtag } from '../../utils/gtag'
import { isLoggedIn, logout } from '../../utils/auth'
import MenuContext from './context'
import StoreContext from '../../contexts/storeContext'
import Cart from '../../icons/cart.svg'

const MobileMenu = () => {
  const context = useContext(MenuContext)
  const { store } = useContext(StoreContext)
  const cartItems = store.checkout ? store.checkout.lineItems : []
  const cartQuantity = cartItems.reduce((acc, item) => {
    return acc + item.quantity
  }, 0)

  const listSX = {
    display: 'flex',
    backgroundColor: 'primary',
    flexDirection: 'column',
    position: 'fixed',
    top: '103px',
    left: '0px',
    width: '100%',
    height: 'calc(100% - 63px)',
    padding: '83px 60px 40px',
    transition: 'all 0.2s linear',
    transform: context.open ? 'translateX(0%)' : 'translateX(-100%)',
    overflowY: 'auto',
  }

  const iconSX = {
    height: '18px',
    width: 'auto',
    mr: 2,
  }

  const handleLogout = async e => {
    e.preventDefault()
    logout()
    window.location.href = '/'
    // navigate('/', { state: {loggedOut: true}});
  }

  const NavItem = x => {
    if (!x.customLink && !x.page) return null

    return (
      <Link
        to={context.getLink(x)}
        sx={{ variant: 'links.nav' }}
        onClick={() => onClickGtag('click', 'Mobile Menu', x.name)}
      >
        {x.name}
      </Link>
    )
  }

  return (
    <Flex gap={1} sx={listSX}>
      {context.nav?.map((x, i) => {
        return <NavItem {...x} key={i} />
      })}
      {isLoggedIn() && (
        <Link to="/" sx={{ variant: 'links.nav' }} onClick={handleLogout}>
          Log out
        </Link>
      )}
      <Link to="/join" sx={{ variant: 'links.nav' }}>
        Join us
      </Link>
      <Link to="/cart" sx={{ variant: 'links.nav' }}>
        <Cart sx={iconSX} />
        Cart {cartQuantity > 0 ? `(${cartQuantity})` : ''}
      </Link>
    </Flex>
  )
}

export default MobileMenu

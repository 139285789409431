export const theme = {
  space: [
    0,
    10,
    15,
    20,
    25,
    30,
    35,
    40,
    45,
    50,
    55,
    60,
    65,
    70,
    75,
    80,
    85,
    90,
  ],
  breakpoints: [
    '500px',
    '650px',
    '750px',
    '800px',
    '960px',
    '1140px',
    '1240px',
    '1400px',
  ],
  fonts: {
    body: '"Open Sans", sans-serif',
    subheading: '"Open Sans", sans-serif',
    heading: '"Zuume Bold", sans-serif',
    headingalt: '"Zuume Cut Bold", sans-serif',
    headingquiet: '"Zuume Semibold", sans-serif',
    light: '"Zuume Light", sans-serif',
  },
  colors: {
    text: '#000',
    background: '#F1F1F1',
    primary: '#70DA22',
    primarydark: '#9BCF2C',
    primarylight: '#ecf9db',
    secondary: '#9BCF2C',
    black: '#121904',
    blacklight: '#1E2906',
    white: '#fff',
    arrow: '#c4c4c4',
    greyborder: '#d3d3d3',
    greyborderlight: '#e4e4e4',
    greybordermenu: '#dcdbdb',
    yellow: '#ffd600',
    red: '#ff0000',
    away: '#EAEAEA',
    goalkeeper: '#f15c97',
    third: '#008cb4',
  },
  fontSizes: [12, 14, 15, 16, 18, 20, 22, 24, 36, 42, 62, 75, 86],
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700,
  },
  lineHeights: {
    body: '1.5em',
    heading: '0.9em',
    subheading: '1.417em',
    compact: '0.9em',
    miniature: '0.85em',
  },
  letterSpacings: {
    body: 'normal',
    caps: '0.2em',
  },
  text: {
    default: {
      fontSize: 3,
      lineHeight: 'body',
    },
    loud: {
      fontSize: 12,
      color: 'primary',
      lineHeight: 'heading',
      fontFamily: 'heading',
    },
    heading: {
      fontSize: 11,
      color: 'white',
      lineHeight: 'miniature',
      fontFamily: 'heading',
    },
    headingsmall: {
      fontSize: 9,
      lineHeight: 'heading',
      fontFamily: 'heading',
    },
    headingdark: {
      fontSize: 12,
      color: 'black',
      lineHeight: 'heading',
      fontFamily: 'heading',
    },
    subheading: {
      fontSize: 7,
      lineHeight: 'subheading',
      color: 'white',
      fontFamily: 'subheading',
      fontWeight: 'body',
    },
    fixtureHeading: {
      fontSize: 6,
      lineHeight: 'heading',
      textTransform: 'uppercase',
      fontFamily: 'heading',
    },
    small: {
      fontSize: 2,
      lineHeight: 'body',
    },
    tableHeading: {
      fontSize: 4,
      lineHeight: 'body',
      fontFamily: 'heading',
    },
    cardHeading: {
      fontSize: 5,
      lineHeight: 'body',
      fontWeight: 700,
    },
    cardCategory: {
      fontSize: 3,
      lineHeight: 'body',
      fontFamily: 'headingalt',
      letterSpacing: '0.1em',
    },
    cardTitle: {
      fontSize: 9,
      lineHeight: 'compact',
      fontFamily: 'headingquiet',
    },
    promoTitle: {
      fontSize: 10,
      lineHeight: 'compact',
      fontFamily: 'heading',
    },
    promoSubtitle: {
      fontSize: 10,
      lineHeight: 'compact',
      fontFamily: 'light',
    },
    banner: {
      backgroundColor: 'primary',
      padding: 2,
      textAlign: 'center',
      fontSize: 5,
      lineHeight: 'body',
      fontWeight: 700,
      fontFamily: 'body',
    },
    error: {
      color: 'red',
      py: 2,
    },
  },
  links: {
    nav: {
      fontSize: [5, null, null, null, null, null, null, 2],
      marginBottom: [4, null, null, null, null, null, null, '0px'],
      color: 'inherit',
      textDecoration: 'none',
      '&.active': {
        fontWeight: 'bold',
      },
    },
    utility: {
      fontSize: [1, null, null, null, null, null, null, 2],
      color: 'inherit',
      textDecoration: 'none',
      '&.active': {
        fontWeight: 'bold',
      },
    },
  },
  buttons: {
    primary: {
      borderRadius: '30px',
      py: 2,
      px: 7,
      color: 'black',
      transition: 'all 0.2s linear',
      cursor: 'pointer',

      '&:hover': {
        backgroundColor: 'black',
        color: 'white',
      },
    },
    secondary: {
      background: 'white',
      borderRadius: '30px',
      py: 2,
      px: 7,
      color: 'black',
      border: '1px solid black',
      transition: 'all 0.2s linear',

      '&:hover': {
        backgroundColor: 'black',
        color: 'white',
      },
    },
    transparent: {
      background: 'transparent',
      borderRadius: '30px',
      py: 2,
      px: 7,
      color: 'black',
      border: '1px solid black',
      transition: 'all 0.2s linear',

      '&:hover': {
        backgroundColor: 'black',
        color: 'white',
      },
    },
    tertiary: {
      backgroundColor: 'black',
      borderRadius: '30px',
      py: 2,
      px: 7,
      color: 'white',
      border: '1px solid black',
      transition: 'all 0.2s linear',

      '&:hover': {
        backgroundColor: 'white',
        color: 'black',
      },
    },
    iconText: {
      display: 'flex',
      backgroundColor: 'black',
      borderRadius: '30px',
      py: 2,
      px: 7,
      color: 'white',
      border: '1px solid black',
      transition: 'all 0.2s linear',
      alignItems: 'center',
      width: '245px',

      '> img': {
        margin: '0px',
        marginRight: 10,
      },

      '&:hover': {
        backgroundColor: 'white',
        color: 'black',
      },
    },
    icon: {
      display: 'flex',
      backgroundColor: 'black',
      borderRadius: '30px',
      transition: 'all 0.2s linear',
      alignItems: 'center',
      justifyContent: 'center',
      width: '35px',
      height: '35px',
      padding: '0px',

      '> img, > svg': {
        margin: '0px',
        maxWidth: '100%',
      },
    },
  },
  cards: {
    primary: {
      padding: [4, null, null, null, null, null, null, 6],
      borderRadius: 8,
      boxShadow: '0 0 8px rgba(0, 0, 0, 0.125)',
      background: 'white',
    },
    slim: {
      padding: '0px',
      borderRadius: 8,
      boxShadow: '0 0 8px rgba(0, 0, 0, 0.125)',
      background: 'white',
      overflow: 'hidden',
    },
    border: {
      padding: '0px',
      borderRadius: 8,
      overflow: 'hidden',
      border: '1px solid',
      borderColor: 'greyborder',
      background: 'white',
    },
    compact: {
      padding: 1,
      borderRadius: 2,
      border: '1px solid',
      borderColor: 'muted',
    },
  },
  forms: {
    radio: {
      borderColor: 'black',
      '&:focus': {
        borderColor: 'black',
        boxShadow: t => `0 0 0 2px ${t.colors.primary}`,
        outline: 'none',
      },
    },
  },
  alerts: {
    primary: {
      lineHeight: 'body',
      whiteSpace: 'pre-line',
      textAlign: 'center',
      color: 'black',

      a: {
        color: 'black',
      },
    },
    error: {
      bg: 'red',
      lineHeight: 'body',
      whiteSpace: 'pre-line',
      textAlign: 'center',

      p: {
        width: '100%',
      },

      a: {
        color: 'white',
      },
    },
  },
}

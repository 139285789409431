/** @jsx jsx */
import { jsx, useThemeUI, get } from 'theme-ui'

const Divider = (props) => {

  const { theme } = useThemeUI()
  const black = get(theme, 'colors.black')

  return (
    <div sx={{
      height: props.height,
      width: 1,
      background: black
    }}></div>
  )
}

export default Divider;
/** @jsx jsx */
import React, { useContext } from 'react'
import { jsx } from 'theme-ui'
import { Inline } from 'raam'
import Divider from './../divider'
import { Link, navigate } from 'gatsby'
import { useMediaQuery } from 'react-responsive'
import Burger from './burger'
import { isLoggedIn } from '../../utils/auth'
import Cart from '../../icons/cart.svg'
import StoreContext from '../../contexts/storeContext'
import { responsive } from '../../utils/helpers'
import { onClickGtag } from '../../utils/gtag'
import style from './utilityMenu.style'

const UtilityMenu = () => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1240px)' })
  const isDesktop = useMediaQuery({ query: '(min-width: 1241px)' })
  const { store } = useContext(StoreContext)
  const items = store.checkout ? store.checkout.lineItems : []
  const cartQuantity = items.reduce((acc, item) => {
    return acc + item.quantity
  }, 0)

  const BurgerMenu = () => {
    if (isDesktop) return null

    return (
      <Inline gap={responsive(2, 5)} sx={{ overflow: 'visible' }}>
        <Divider height={'42px'} />
        <Burger />
      </Inline>
    )
  }

  const CartIcon = () => {
    // if (isTabletOrMobile) return null

    return (
      <Inline gap={responsive(2, 5)} sx={{ overflow: 'visible' }}>
        <Divider
          height={['42px', null, null, null, null, null, null, '52px']}
        />
        <Link
          activeStyle={style.activeLink}
          to="/cart"
          sx={{ variant: 'links.utility' }}
        >
          <Cart sx={iconSX} />
          {!isTabletOrMobile &&
            `Cart ${cartQuantity > 0 ? `(${cartQuantity})` : ''}`}
        </Link>
      </Inline>
    )
  }

  const iconSX = {
    height: '18px',
    width: 'auto',
    mr: [null, null, null, null, null, null, null, 2],
  }

  if (isLoggedIn()) {
    return (
      <Inline gap={responsive(2, 5)} as="ul">
        <Link
          to="/my-fgr"
          sx={{ variant: 'links.utility' }}
          activeStyle={style.activeLink}
          onClick={() => onClickGtag('click', 'Utility Menu', 'My FGR')}
        >
          My FGR
        </Link>
        {!isTabletOrMobile && (
          <Divider
            height={['42px', null, null, null, null, null, null, '52px']}
          />
        )}
        {!isTabletOrMobile && (
          <Link
            to="/join"
            sx={{ variant: 'links.utility' }}
            activeStyle={style.activeLink}
          >
            Join us
          </Link>
        )}
        <CartIcon />
        <BurgerMenu />
      </Inline>
    )
  }

  return (
    <Inline gap={responsive(2, 5)} as="ul">
      <Link
        to="/login"
        sx={{ variant: 'links.utility' }}
        activeStyle={style.activeLink}
        onClick={() => onClickGtag('click', 'Utility Menu', 'Log in')}
      >
        Log in
      </Link>
      {!isTabletOrMobile && (
        <Divider
          height={['42px', null, null, null, null, null, null, '52px']}
        />
      )}
      {!isTabletOrMobile && (
        <Link
          to="/join"
          sx={{ variant: 'links.utility' }}
          activeStyle={style.activeLink}
        >
          Join us
        </Link>
      )}
      <CartIcon />
      <BurgerMenu />
    </Inline>
  )
}

export default UtilityMenu

/** @jsx jsx */
import { jsx, Box, Text } from 'theme-ui';

import Ecotricity from '../../images/ecotricity.png'

const Powered = () => {

  const containerSX = {
    margin: '130px 0',
    textAlign: 'center'
  }

  const imageSX = {
    width: '159px',
    height: '36px',
    margin: '0 auto'
  }

  return (
    <Box sx={containerSX}>
      <Text mb={2}>Powered By</Text>
      <img sx={imageSX} src={Ecotricity} alt="Ecotricity" />
    </Box>
  )
}

export default Powered
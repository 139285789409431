import { navigate } from "gatsby"
import { Auth } from 'aws-amplify'
import moment from 'moment';

const isBrowser = typeof window !== `undefined`

export const login = (email, password) => {
  return new Promise( async (resolve, reject) => {
    try {
      const user = await Auth.signIn({username: email, password: password});
      resolve(user)
    } catch (error) {
      reject(error?.message)
    }
  })
}

export const logout = async () => {
  setUser(false)
  await Auth.signOut();
}

export const register = async (email, password, attributes) => {
  return new Promise( async (resolve, reject) => {
    try {
        const { user } = await Auth.signUp({
          username: email,
          password: password,
          attributes: attributes
        });
        resolve(user)
    } catch (error) {
        reject(error?.message)
    }
  });
}

export const currentAuthenticatedUser = async () => {
  return await Auth.currentAuthenticatedUser()
}

export const setUser = (user) => {
  if (!isBrowser)
    return
  try {
    localStorage.setItem("user", JSON.stringify(user))
  } catch (e) {
    console.log('local storage could be full', e);
    // localStorage.clear();
    // setUser(user)
  }
  
}

export const getUser = () => {
  if (!isBrowser)
    return
  if (!localStorage.user)
    return false

  const user = JSON.parse(localStorage.user)
  return user || false
}

export const isLoggedIn = () => {
  const user = getUser()
  return user
}

export const forgotPassword = (email) => {
  return Auth.forgotPassword(email)
    .then((data) => {
      return {success: true, data: data}
    })
    .catch((err) => {
      throw err
    });
}

export const forgotPasswordSubmit = (email, code, newPassword) => {
  return Auth.forgotPasswordSubmit(email, code, newPassword)
    .then((data) => {
      return {success: true, data: data}
    })
    .catch((err) => {
      throw err
    });
}

export const getIdToken = async (showErrors = false) => {
  // Code block below attempts to get token from the user in local storage
  // Leave this commented out to always get a refreshed token

  // const user = getUser();
  // if (!user)
  //   return showErrors ? {success: false, error: 'Session expired, please login'} : false;
  // const storedToken = user.signInUserSession?.idToken;
  // if (storedToken && validateIDToken(storedToken))
  //   return showErrors ? {success: true, data: storedToken.jwtToken} : storedToken.jwtToken; 

  // current token is invalid, refresh
  const refreshed = await refreshSession()
  if (!refreshed.success)
    return showErrors ? refreshed : false
  
  const refreshedToken = refreshed.data?.idToken?.jwtToken
  if (showErrors) {
    if (refreshedToken) {
      return {success: true, data: refreshedToken}
    } else {
      return {success: false, error: 'Invalid token, please login again'}
    }
  } else {
    return refreshedToken
  }
}

// Returns whether or not this ID token has expired
export const validateIDToken = (idToken) => {
  const exp = idToken?.payload?.exp;
  if (!exp)
    return false

  return moment.unix(exp) > moment()
}

// This method will automatically refresh the accessToken and idToken if tokens are expired and a valid refreshToken presented. So you can use this method to refresh the session if needed.
// Source: https://docs.amplify.aws/lib/auth/manageusers/q/platform/js#retrieve-current-session
export const refreshSession = () => {
  return Auth.currentSession()
    .then((data) => {
      // Store new token for user
      const user = getUser();
      const refreshedUser = {
        ...user,
        signInUserSession: data
      }
      setUser(refreshedUser)
      return {success: true, data: data}
    })
    .catch((err) => {
      return {success: false, error: err}
    });
}

export const getAuthHeader = async (showErrors = false) => {
  const token = await getIdToken(showErrors)
  if ((showErrors && !token.success) || (!showErrors && !token))
    return showErrors ? token : ''
  const header = {Authorization: `Bearer ${showErrors ? token.data : token}`}
  return showErrors ? {success: true, data: header} : header
}

export const changePassword = async (oldPassword, newPassword) => {
  const user = await currentAuthenticatedUser()
  return Auth.changePassword(user, oldPassword, newPassword)
    .then(data => {
      return {success: true, data: data}
    })
    .catch(err => {
      return {success: false, error: err}
    })
}

export const updateUser = async (attributes) => {
  const user = await currentAuthenticatedUser()
  return Auth.updateUserAttributes(user, attributes)
    .then(async (data) => {
      const user = await currentAuthenticatedUser()
      setUser(user)
      return {success: true, data: data}
    })
    .catch(err => {
      return {success: false, error: err}
    })
}

export const resendVerificationCode = async (username) => {
  try {
    await Auth.resendSignUp(username);
    return {success: true, data: 'code resent successfully'}
  } catch (err) {
    return {success: false, error: err}
  }
}

export const confirmSignup = async (username, code) => {
  try {
    await Auth.confirmSignUp(username, code);
    return {success: true, data: `${username} has been successfully confirmed`}
  } catch (err) {
    return {success: false, error: err}
  }
}
/** @jsx jsx */
import { useContext, useState } from 'react'
import { jsx, Text, Flex, Box, Button, Input, Label } from 'theme-ui'
import StoreContext from '../../contexts/storeContext'
import { Link } from 'gatsby'
import { Stack } from 'raam'
import { responsive } from '../../utils/helpers'

const LineItem = (props) => {

  const { store, removeLineItem, updateLineItem } = useContext(StoreContext)
  const [removing, setRemoving] = useState()
  const [updating, setUpdating] = useState()
  const [qty, setQty] = useState(props.quantity)

  const VariantImage = () => {
    if (!props.variant.image)
      return <Text>{props.title}</Text>
    return (
      <img
        src={props.variant.image.src}
        alt={`${props.title}`}
        height="60px"
      />
    )
  }

  const SelectedOptions = () => {
    if (!props.variant.selectedOptions)
      return null

    return (
      <Stack sx={{lineHeight: 'body'}}>
        {props.variant.selectedOptions.map((x,i) => {
          return `${x.name}: ${x.value} `
        })}
      </Stack>
    )
  }

  const CustomAttributes = () => {
    const attributes = props.customAttributes.filter(x => x.key && x.value)
    if (!attributes.length)
      return null

      return (
        <Stack sx={{lineHeight: 'body'}}>
          {attributes.map((x) => {
            return `${x.key}: ${x.value} `
          })}
        </Stack>
      )
  }

  const handleRemove = async () => {
    setRemoving(true)
    await removeLineItem(store.client, store.checkout.id, props.id)
    setRemoving(false)
  }

  const handleUpdate = async () => {
    if (updating) return
    setUpdating(true)
    await updateLineItem(store.client, store.checkout.id, [{id: props.id, quantity: parseInt(qty)}])
    setUpdating(false)
  }

  const containerSX = {
    width: '100%',
    display: responsive('block', 'flex'),
    borderBottom: props.last ? 'none' : '1px solid black',
    borderColor: 'greyborder',
    paddingBottom: 4,
    opacity: removing ? 0.3 : 1,
    pointerEvents: removing ? 'none' : 'all',
  }

  const slim = {
    width: responsive('100%', '20%')
  }

  const wide = {
    width: responsive('100%', '40%')
  }

  const actionsSX = {
    ...slim,
    textAlign: responsive('left', 'right')
  }

  const linkSX = {
    ...slim,
    marginBottom: responsive(2, '0px'),
    color: 'black',
    display: 'block',
  }

  const descriptionSX = {
    marginBottom: responsive(2, '0px'),
  }

  const labelSX = {
    width: 'auto'
  }

  const inputSX = {
    height: '20px',
    flexGrow: 1,
    width: 'auto'
  }

  return (
    <Box sx={containerSX}>
      <Link to={`/shop/${props.variant.product.handle}`} sx={linkSX}>
        <VariantImage />
      </Link>
      <Box sx={{...wide, ...descriptionSX}}>
        <Text as="p" sx={{lineHeight: 'body'}}>
          {props.title}
          {`  `}
          {props.variant.title === !'Default Title' ? props.variant.title : ''}
        </Text>
        <SelectedOptions />
        <CustomAttributes />
      </Box>
      <Flex sx={{...slim, ...descriptionSX}}>
        <Label sx={labelSX} mr={5}>Qty: </Label>
        <Input 
          type="number" 
          min="1"
          step="1" 
          value={qty} 
          sx={inputSX} 
          onChange={e => setQty(e.target.value)} 
        />
      </Flex>
      <Stack sx={actionsSX} gap={4}>
        <Button variant="secondary" onClick={handleUpdate}>{updating ? 'Wait' : 'Update'}</Button>
        <Button variant="tertiary" onClick={handleRemove}>Remove</Button>
      </Stack>
    </Box>
  )
}

export default LineItem
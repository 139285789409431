/** @jsx jsx */
import React, { useState, useContext } from 'react'
import { jsx, Box, Button, Text } from 'theme-ui';
import Progress from './progress'
import Wrapper from '../layout/wrapper'
import Inner from '../layout/inner'
import FullScreen from '../layout/fullScreen'

const MultipartContext = React.createContext({screen: 0, setScreen: false})

const Multipart = (props) => {

  const [screen, setScreen] = useState(0)

  

  return (
    <MultipartContext.Provider value={{screen: screen, setScreen: setScreen, total: props.total}}>
      {props.children}
    </MultipartContext.Provider>
  )
}

const Part = (props) => {

  const context = useContext(MultipartContext)

  if (!context || context.screen !== props.id)
    return null

  const Container = props.fullScreen ? FullScreen : Box

  return (
    <Container {...props.fullScreen}> 
      {props.children}
    </Container>
  )
}

const NextButton = (props) => {

  const context = useContext(MultipartContext)

  return (
    <Button 
      variant={props.variant} 
      className={props.className}
      onClick={() => context.setScreen(context.screen + 1)}
    >
      {props.children}
    </Button>
  )
}

const SkipButton = (props) => {

  const context = useContext(MultipartContext)

  return (
    <Text 
      variant={props.variant} 
      className={props.className}
      onClick={() => context.setScreen(context.total - 1)}
      sx={{
        cursor: 'pointer',
        py: 4,
      }}
    >
      {props.children}
    </Text>
  )
}

const PartProgress = (props) => {

  const context = useContext(MultipartContext)

  const progressSX = {
    pt: 7, 
    pb: 11
  }

  const innerSX = {
    display: 'flex',
    justifyContent: 'center'
  }

  return (
    <Wrapper>
      <Inner sx={innerSX}>   
        <Progress 
          steps={context.total} 
          active={context.screen} 
          onClick={context.setScreen} 
          sx={progressSX} 
          activeColour={props.activeColour}
          inactiveColour={props.inactiveColour}
          noInteraction={props.noInteraction}
        />
      </Inner>
    </Wrapper>
  )
}

export default Multipart
export { Part, NextButton, PartProgress, SkipButton, MultipartContext }